import sendBeaconTracking from './kamino_tracking_init.js';

const VISIBILITY_THRESHOLD = 0.5;

// Fonction pour envoyer les paramètres avec sendBeaconTracking
function sendTracking(params) {
    if (!params) return;

    const url = new URLSearchParams(params).toString();
    sendBeaconTracking(url);
}

// Fonction pour vider le localStorage des clés spécifiques
function clearTrackingData() {
    Object.keys(localStorage).forEach((key) => {
        if (key.endsWith('_visibleTime') || key.endsWith('_hasTracked')) {
            localStorage.removeItem(key);
        }
    });
}

// Fonction pour suivre la visibilité du conteneur de la vidéo pendant 0, 1, et 2 secondes
const trackContainerVisibility = (videoContainer, viewBeacon, videoId) => {
    let visibleTime = parseInt(localStorage.getItem(`${videoId}_visibleTime`)) || 0;
    let hasTracked = localStorage.getItem(`${videoId}_hasTracked`) === 'true';
    let visibilityInterval;

    const startVisibilityInterval = () => {
        visibilityInterval = setInterval(() => {
            if (visibleTime <= 2) {
                sendBeaconTracking(viewBeacon + '&act=step&st=' + visibleTime);
                visibleTime++;
                localStorage.setItem(`${videoId}_visibleTime`, visibleTime);
            }
            if (visibleTime > 2) {
                clearInterval(visibilityInterval);
                hasTracked = true;
                localStorage.setItem(`${videoId}_hasTracked`, 'true');
            }
        }, 1000);
    };

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.intersectionRatio >= VISIBILITY_THRESHOLD) {
                    if (!hasTracked) {
                        startVisibilityInterval();
                    }
                } else {
                    clearInterval(visibilityInterval);
                }
            });
        },
        { threshold: VISIBILITY_THRESHOLD }
    );

    observer.observe(videoContainer);

    return () => {
        observer.disconnect();
        clearInterval(visibilityInterval);
    };
};
const checkpoints = [0, 0.25, 0.5, 0.75, 1];
// Fonction d'initialisation du tracking pour chaque vidéo
const initVideoTracking = (video) => {
    const videoParent = video.closest('.video-area');
    const videoUrl = videoParent?.getAttribute('data-video-url');
    const videoId = video.getAttribute('id');
    if (!videoUrl) {
        console.error('Missing video URL');
        return;
    }

    const clickBeacon = videoParent.getAttribute('data-click-beacon');
    const videoBeacon = videoParent.getAttribute('data-video-beacon');
    const viewBeacon = videoParent.getAttribute('data-view-beacon');

    if (!clickBeacon || !videoBeacon || !viewBeacon) {
        console.error('Missing beacon data in video element:', { clickBeacon, videoBeacon, viewBeacon });
        return;
    }
    video.addEventListener('pause', () => {
        const params = new URLSearchParams(clickBeacon + '&click_zone=video&act=pause');
        sendTracking(params);
    });

    video.addEventListener('play', () => {
        const params = new URLSearchParams(clickBeacon + '&click_zone=video&act=play');
        sendTracking(params);
    });

    video.addEventListener('volumechange', function () {
        let muteStatus;
        if (video.muted) {
            muteStatus = 'mute';
        } else {
            muteStatus = 'unmute';
        }
        sendTracking(new URLSearchParams(clickBeacon + '&click_zone=video&&act=' + muteStatus));
    });

    // Suivi des checkpoints de visionnage
    const checkpointsReached = new Set(); // Pour éviter de re-déclencher le même checkpoint

    video.addEventListener('timeupdate', () => {
        const currentProgress = video.currentTime / video.duration;

        checkpoints.forEach((checkpoint) => {
            if (currentProgress >= checkpoint && !checkpointsReached.has(checkpoint)) {
                checkpointsReached.add(checkpoint);
                // Calculer le pourcentage d'avancement pour le suivi
                const stepPercentage = Math.round(checkpoint * 100);
                const params = new URLSearchParams(videoBeacon + '&act=step&st=' + stepPercentage);
                sendTracking(params);
            }
        });
    });

    trackContainerVisibility(videoParent, viewBeacon, videoId);
};

// Fonction d'initialisation du tracking Kamino pour toutes les vidéos sur la page
export default function kaminoTrackingInit() {
    // Vider le localStorage pour les clés de tracking spécifiques
    clearTrackingData();

    document.querySelectorAll('.video-area').forEach((item) => {
        const video = item.querySelector('video[id^="billboard-video-"]');
        if (video) {
            initVideoTracking(video);
        } else {
            console.error('No video found in video area');
        }
    });
}
