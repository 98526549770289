import Cookies from 'js-cookie';
import splide from './splide.js';

export default function earlybirdsInit(specificWidget) {
    const widgets = [];
    const widgetsConfig = {};
    let ajaxData;

    const getWidgetsAndConfig = () => {
        if (specificWidget) {
            const {
                id,
                dataset: { earlybirds, codic = null, earlybirdsUrl }
            } = specificWidget;
            widgets.push({
                containerId: id,
                widgetId: earlybirds,
                codic
            });

            widgetsConfig[id] = {
                container: specificWidget,
                ajaxUrl: earlybirdsUrl
            };
        } else {
            document.querySelectorAll('[data-earlybirds]').forEach((widgetElt) => {
                const {
                    id,
                    dataset: { earlybirds, codic = null, earlybirdsUrl }
                } = widgetElt;

                widgets.push({
                    containerId: id,
                    widgetId: earlybirds,
                    codic
                });

                widgetsConfig[id] = {
                    container: widgetElt,
                    ajaxUrl: earlybirdsUrl
                };
            });
        }
    };

    const getProfileId = () => {
        const cookieEB = Cookies.get('eb-profile');
        return cookieEB && cookieEB.length ? cookieEB.split(':')[0] : '';
    };

    const activateWidget = (containerId) => {
        const { container } = widgetsConfig[containerId];
        const slider = container.querySelector('.splide');
        if (slider) {
            const {
                dataset: { itemsPerPageLaptop, itemsPerPageDesktop, itemsPerPageDesktopXl }
            } = slider;
            splide(
                slider,
                { laptop: itemsPerPageLaptop, desktop: itemsPerPageDesktop, desktopXL: itemsPerPageDesktopXl },
                false,
                true
            );
            if (!container.closest('.multi-slider')) {
                container.classList.add('js-active');
            }
        }
    };

    const loadWidget = (ajaxDataParam, containerId) => {
        fetch(widgetsConfig[containerId].ajaxUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: new URLSearchParams(ajaxDataParam).toString()
        })
            .then((response) => response.text())
            .then((data) => {
                // si la reponse contient un 'doctype' == on est sur une page 404
                if (!data.length || data.includes('!doctype html')) return;
                const recoLoaded = new Event(
                    containerId === 'custom-pack-early-birds'
                        ? 'ajax_reco_loaded.custom_pack'
                        : 'ajax_reco_loaded.earlybirds'
                );

                if (containerId === 'custom-pack-early-birds') {
                    widgetsConfig[containerId].container.insertAdjacentHTML('beforebegin', data);
                } else {
                    widgetsConfig[containerId].container.insertAdjacentHTML('beforeend', data);
                }

                document.dispatchEvent(recoLoaded);
            })
            .finally(() => {
                activateWidget(containerId);
            });
    };

    const deleteWidget = (containerId) => {
        const { container } = widgetsConfig[containerId];
        const btnRef = container.getAttribute('data-ref');
        const closestMultiSlider = container.closest('.multi-slider');
        const targetElement = closestMultiSlider?.querySelector(`[data-slider-target='${btnRef}']`);

        if (targetElement) targetElement.remove();
        container.remove();
    };

    const mapRecommendations = (recommendations) =>
        recommendations.map(({ id, clickUrl, impressionUrl, distribution, strategy }) => ({
            id,
            clickUrl,
            impressionUrl,
            distribution,
            strategy
        }));

    const queryEBApiSuccessCallback = (thisWidgetId, data, containerId) => {
        if (data[thisWidgetId]?.recommendations.length) {
            ajaxData.items = JSON.stringify(mapRecommendations(data[thisWidgetId].recommendations));
            loadWidget(ajaxData, containerId);
        } else {
            deleteWidget(containerId);
        }
    };

    const queryEBApi = (widget) => {
        ajaxData = { variables: {} };
        const widgetCodic = widget.codic || null;

        if (widgetCodic) ajaxData.variables.$productId = widgetCodic;

        const params = new URLSearchParams({ variables: JSON.stringify(ajaxData.variables) });
        fetch(`//api.early-birds.fr/widget/multi/${widget.widgetId}/recommendations/${getProfileId()}?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                queryEBApiSuccessCallback(widget.widgetId, data, widget.containerId);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    getWidgetsAndConfig();
    if (widgets.length) {
        widgets.forEach(queryEBApi);
    }
}
