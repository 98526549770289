import { splideInit } from '../splide.js';
import micromodal from '../micromodal_init.js';
import kaminoTracking from './billboard_tracking_video.js';
import sendBeaconTracking from './kamino_tracking_init.js';
import sendProductTracking from './kamino_product_tracking.js';

// Constantes pour les marges de temps
const MAX_TIME_DIFF = 0.5;

// Initialise la vidéo de fond
const initVideoBackground = (videoSrc, id) => {
    const video = document.createElement('video');
    video.setAttribute('id', 'background-' + id);
    video.src = videoSrc;
    video.autoplay = true;
    video.muted = true;
    video.loop = false;
    video.play();
    const canvasId = ('canvas-' + id).replace('_html5_api', '');
    const canvas = document.querySelector(`[id^=${canvasId}]`);
    if (!canvas) {
        console.error(`Canvas element with ID ${canvasId} not found while initializing video background.`);
        return null;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
        console.error('Cannot get canvas context while initializing video background.');
        return null;
    }

    const draw = () => {
        ctx.filter = 'blur(10px)';
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        requestAnimationFrame(draw);
    };

    draw();

    return video;
};

// Synchronise les contrôles vidéo
const syncVideoControls = (mainVideo, originalBackgroundVideo) => {
    const backgroundVideo = videojs(originalBackgroundVideo);

    if (backgroundVideo) {
        ['play', 'pause', 'ended'].forEach((event) => {
            mainVideo.on(event, () => {
                if (event === 'ended') {
                    backgroundVideo.pause();
                } else {
                    backgroundVideo[event]();
                }
            });
        });

        mainVideo.on('timeupdate', () => {
            const timeDiff = Math.abs(mainVideo.currentTime() - backgroundVideo.currentTime);
            if (timeDiff > MAX_TIME_DIFF) {
                backgroundVideo.currentTime = mainVideo.currentTime();
            }
        });
    }
};

// Initialise les composants dynamiques
const initializeDynamicComponents = (container) => {
    splideInit();
    micromodal();
    sendProductTracking(container);
    const videoContainer = document.querySelector('.clickable-area');
    if (!videoContainer) {
        console.error('Video container not found during dynamic components initialization.');
        return;
    }

    const videoUrl = videoContainer.getAttribute('data-video-url');
    if (!videoUrl) {
        console.warn('No video URL found during dynamic components initialization.');
        return;
    }

    const mainVideoElement = videoContainer.querySelector('video');
    if (!mainVideoElement) {
        console.error('Main video element not found during dynamic components initialization.');
        return;
    }

    const mainVideo = videojs(mainVideoElement.id);
    const isPortraitFormat = videoContainer.classList.contains('portrait-format');
    let backgroundVideo;
    if (isPortraitFormat) {
        backgroundVideo = initVideoBackground(videoUrl, mainVideoElement.id);
        if (!backgroundVideo) {
            console.error('Background video initialization failed during dynamic components initialization.');
            return;
        }
        syncVideoControls(mainVideo, backgroundVideo);
    }
    kaminoTracking();
};

// Récupère le contenu publicitaire
const fetchAdvertisementContent = async () => {
    const params = new URLSearchParams(window.location.search);
    const url = '/nav/extra/ajax/advertisement/home_page?' + params.toString();
    try {
        const response = await fetch(url);
        const contentType = response.headers.get('content-type');

        if (!contentType?.includes('application/json')) {
            const text = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');
            const billboardContainer = doc.querySelector('.billboard-container');

            if (billboardContainer) {
                return billboardContainer.outerHTML;
            }
            throw new Error('Billboard-container not found while fetching advertisement content.');
        }

        const data = await response.json();
        if (typeof data === 'string') {
            return data;
        }
        if (data.html) {
            return data.html;
        }
        throw new Error('Unexpected data structure while fetching advertisement content.');
    } catch (error) {
        console.error('Error fetching advertisement content:', error);
        throw error;
    }
};

// Initialise les publicités de la page d'accueil
export default function homePageAdsInit() {
    document.addEventListener('DOMContentLoaded', () => {
        (async () => {
            const container = document.querySelector('.billboard-content');
            if (!container) {
                console.error('Billboard content container not found during home page ads initialization.');
                return;
            }
            try {
                container.innerHTML = await fetchAdvertisementContent();
                const impBeacon = container.querySelector('.billboard-container').getAttribute('data-imp-beacon');
                sendBeaconTracking(impBeacon);
                initializeDynamicComponents(container);
            } catch (error) {
                console.error('Error during home page ads initialization:', error);
            }
        })();
    });
}
