import sendBeaconTracking, { clickTrackingData } from './kamino_tracking_init.js';

const addToCartClick = function (container, beaconData) {
    const addToCartBtns = container.querySelectorAll('.button-add-basket');
    for (const addToCartBtn of addToCartBtns) {
        addToCartBtn.addEventListener('click', function () {
            const productId = this.closest('.product_pub-card').getAttribute('data-codic');
            sendBeaconTracking(
                clickTrackingData(beaconData, 'click_zone=product&act=add-to-cart&product_id=' + productId)
            );
        });
    }
};

const productClick = function (container, beaconData) {
    const productLinks = container.querySelectorAll('.product_pub-link');

    for (const productLink of productLinks) {
        productLink.addEventListener('click', function () {
            const productId = this.closest('.product_pub-card').getAttribute('data-codic');
            sendBeaconTracking(
                clickTrackingData(beaconData, 'click_zone=product&act=product-page&product_id=' + productId)
            );
        });
    }
};

const slideNavigationTracking = function (container, beaconData) {
    const nextBtn = container.querySelector('.splide__arrow--next');
    const prevBtn = container.querySelector('.splide__arrow--prev');

    if (nextBtn) {
        nextBtn.addEventListener('click', () => {
            sendBeaconTracking(clickTrackingData(beaconData, 'click_zone=format&act=nav-next'));
        });
    }

    if (prevBtn) {
        prevBtn.addEventListener('click', () => {
            sendBeaconTracking(clickTrackingData(beaconData, 'click_zone=format&act=nav-prev'));
        });
    }
};

export default function sendProductTracking(container) {
    const clickBeaconData = container.querySelector('.billboard-container').getAttribute('data-click-beacon');
    addToCartClick(container, clickBeaconData);
    productClick(container, clickBeaconData);
    slideNavigationTracking(container, clickBeaconData);
}
