import Cookies from 'js-cookie';
import SaveGeoloc from './save_geoloc.js';
import eventEmitter from './events.js';

let containerElement;
let suggestionsElement;
let inputElement;

function fillGeolocInput() {
    const city = Cookies.get('PCKCITY');
    const zipCode = Cookies.get('USERZIPCODE');
    if (!city || !zipCode) return '';
    const label = `${city} (${zipCode})`;

    return label;
}

const manageSelection = (form, input, container, ref) => {
    let focusedSuggestionIndex = -1;
    containerElement = container;
    inputElement = input;

    input.addEventListener('keydown', (event) => {
        const suggestions = Array.from(form.closest('.searchbar').querySelectorAll('.suggestions_list--item'));

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            focusedSuggestionIndex = (focusedSuggestionIndex + 1) % suggestions.length;
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            focusedSuggestionIndex = (focusedSuggestionIndex - 1 + suggestions.length) % suggestions.length;
        } else if (event.key === 'Enter' && suggestions.length > 0) {
            event.preventDefault();
            if (focusedSuggestionIndex === -1) focusedSuggestionIndex = 0;
            SaveGeoloc(ref[focusedSuggestionIndex].value);
            eventEmitter.emit('selectGeolocation');
            containerElement.innerHTML = '';
            inputElement.blur();
        }

        suggestions.forEach((item, index) => {
            if (index === focusedSuggestionIndex) {
                item.focus();
                item.classList.add('js-focused');
                item.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                inputElement.value = item.textContent;
            } else {
                item.blur();
                item.classList.remove('js-focused');
            }
        });
    });
};

const displaySuggestions = (form, input, suggestions) => {
    const container = form.closest('.searchbar').querySelector('.js-clean-suggestions');
    const list = document.createElement('ul');
    inputElement = input;
    list.classList.add('suggestions_list');

    suggestions.forEach((suggestion) => {
        const item = document.createElement('li');
        item.classList.add('suggestions_list--item');
        item.textContent = suggestion.label;

        item.addEventListener('click', () => {
            SaveGeoloc(suggestion.value);
            inputElement.value = suggestion.label;
            container.innerHTML = '';
            eventEmitter.emit('selectGeolocation');
        });

        list.appendChild(item);
    });

    container.innerHTML = '';
    container.appendChild(list);
    manageSelection(form, input, container, suggestions);
};

async function getGeolocDatas(form, input, term, url) {
    const errorRequest = form.closest('.main').querySelector('.request');
    const response = await fetch(`${url}?zipCode=${term}`);
    const datas = await response.json();

    if (datas.errorMessage) {
        errorRequest.classList.add('js-active');
    } else {
        errorRequest.classList.remove('js-active');

        const suggestions = datas.cities.map((data) => ({
            label: `${data.name} (${data.zipCode})`,
            value: data
        }));

        displaySuggestions(form, input, suggestions);
    }
}

function cleanAutocomplete(form, input, suggestions, url) {
    suggestionsElement = suggestions;
    inputElement = input;
    const clearSuggestions = () => {
        suggestionsElement.innerHTML = '';
    };

    input.addEventListener('focus', () => {
        inputElement.value = '';
    });

    input.addEventListener('input', () => {
        const term = inputElement.value;
        const status = form.closest('.main').querySelector('.status');

        if (status) {
            status.classList.remove('js-valid', 'js-invalid');
        }

        if (term.length === 5) {
            getGeolocDatas(form, input, term, url);
        } else {
            clearSuggestions();
        }
    });

    document.addEventListener('click', (event) => {
        if (!form.contains(event.target)) {
            clearSuggestions();
        }
    });
}

export { cleanAutocomplete, fillGeolocInput };
