export const clickTrackingData = (beaconData, params) => {
    return beaconData + '&' + params;
};

export default function sendBeaconTracking(data) {
    const trackingUrl = '/nav/extra/ajax/advertisement/tracking';
    const trackingData = new URLSearchParams({ params: data.toString() });
    const blob = new Blob([trackingData], { type: 'application/x-www-form-urlencoded' });
    navigator.sendBeacon(trackingUrl, blob);
}
