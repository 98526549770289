import adsNexusInit from '../ads_nexus.js';
import multiSlidersInit from '../multi_sliders.js';
import earlybirdsInit from '../earlybirds.js';
import { splideInit } from '../splide.js';
import homePageAdsInit from '../kamino/billboard.js';
import addDeliverableInit from '../add_deliverable.js';

(() => {
    adsNexusInit();
    earlybirdsInit();
    splideInit();
    homePageAdsInit();
    window.addEventListener('load', () => {
        multiSlidersInit();
    });
    addDeliverableInit();
})();
